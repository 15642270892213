import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store'

import { auth } from '@/firebase'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/courses',
		name: 'courses',
		component: () => import('../views/Courses.vue'),
		props: true,
		meta: {
			title: 'Courses'
		}
	},
	{
		path: '/star',
		name: 'star',
		component: () => import('../views/STAR.vue'),
		props: route => ({ result: route.query.result, entryId: route.query.id }),
		meta: {
			title: 'Encore Star'
		}
	},
	{
		path: '/courses/:prefix',
		name: 'coursesPrefix',
		component: () => import('../views/Courses.vue'),
		props: true,
		meta: {
			title: 'Courses'
		}
	},
	{
		path: '/smart',
		name: 'smart',
		component: () => import('../views/SMART.vue'),
		props: true,
		meta: {
			title: 'Encore SMART Teaching'
		}
	},
	{
		path: '/smart/:section',
		name: 'smartSection',
		component: () => import('../views/SMART.vue'),
		props: true,
		meta: {
			title: 'Encore SMART Teaching'
		}
	},
	{
		path: '/for-teachers',
		name: 'forTeachers',
		component: () => import('../views/ForTeachers.vue'),
		props: true,
		meta: {
			title: 'For Teachers'
		}
	},
	{
		path: '/for-teachers/:section',
		name: 'forTeachersSection',
		component: () => import('../views/ForTeachers.vue'),
		props: true,
		meta: {
			title: 'For Teachers'
		}
	},
	{
		path: '/teachers',
		name: 'teachers',
		component: () => import('../views/Teachers.vue'),
		meta: {
			title: 'Find a Teacher'
		}
	},
	{
		path: '/teachers/edit',
		name: 'editTeachers',
		component: () => import('../views/EditTeachers.vue'),
		beforeEnter: async (to, from, next) => {
			if (store.state['user'].isAdmin) {
				next()
			} else {
				next('login')
			}
		},
		meta: {
			title: 'Find a Teacher'
		}
	},
	{
		path: '/videos',
		name: 'videos',
		component: () => import('../views/Resources.vue'),
		props: route => ({
			pCollection: route.query.pCollection,
			pSubCollection: route.query.pSubCollection,
			type: "video",
			title: "Video Library",
			showVideo: true,
			subtitle: "View our selection of free videos or login to enjoy the benefits of our comprehensive video library."
		}),
		meta: {
			title: 'Video Library'
		}
	},
	{
		path: '/teachingnotes',
		name: 'teachingNotes',
		component: () => import('../views/Resources.vue'),
		props: {
			type: "notes",
			title: "Teaching Notes",
			subtitle: "Use our comprehensive Teaching Notes for Smart Teaching ~ Faster Learning."
		},
		meta: {
			title: 'Teaching Notes'
		}
	},
	{
		path: '/marketing',
		name: 'marketingMaterials',
		component: () => import('../views/Resources.vue'),
		props: {
			type: "marketing",
			title: "Marketing Materials",
			subtitle: "Please login to access the marketing materials for Licensees."
		},
		meta: {
			title: 'Marketing Materials'
		}
	},
	{
		path: '/downloads',
		name: 'freeDownloads',
		component: () => import('../views/Resources.vue'),
		props: {
			type: "downloads",
			title: "Free Downloads",
			subtitle: "We're helping you to create Smart Musicians! Enjoy the following resources for free."
		},
		meta: {
			title: "Free Downloads"
		}
	},
	{
		path: '/musicwiz',
		name: 'musicwiz',
		component: () => import('../views/MusicWiz.vue'),
		meta: {
			title: 'Encore Music Wiz'
		}
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import('../views/Support.vue'),
		props: true,
		meta: {
			title: 'Contact'
		}
	},
	{
		path: '/login',
		name: "login",
		component: () => import('../views/Login.vue'),
		beforeEnter: (to, from, next) => {
			if (store.getters['user/isLoggedIn']) {
				next('/')
			} else {
				next()
			}
		},
		props: route => ({ next: route.params.redirect }),
		meta: {
			title: 'Login'
		}
	},
	{
		path: '/logout',
		name: "logout",
		beforeEnter: (to, from, next) => {
			auth.signOut()
			.then(() => {
				return store.dispatch("user/unbindUserBinds")
			})
			.then(() => {
				next('/')
			});
		},
		meta: {
			title: 'Logout'
		}
	},
	{
		path: '/watch',
		name: 'watch',
		component: () => import('../views/VideoPlayer.vue'),
		props: route => ({ videoId: route.query.id, subCollection: route.query.subCollection, collection: route.query.collection }),
		meta: {
			title: 'Training Videos'
		}
	},
	{
		path: '/order',
		name: 'order',
		component: () => import('../views/Order.vue'),
		props: true,
		meta: {
			title: 'Order'
		}
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../views/About.vue'),
		meta: {
			title: 'About Us'
		}
	},
	{
		path: '/reset',
		name: "reset",
		component: () => import('../views/Login.vue'),
		props: route => ({ actionParams: route.query }),
		meta: {
			title: 'Reset Password'
		}
	},
	{
		path: '*',
		component: () => import('../views/NotFound.vue'),
		meta: {
			title: 'Page Not Found'
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	routes: routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
})

export default router
