import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import resources from './modules/resources'
import teachers from './modules/teachers'
import { vuexfireMutations } from 'vuexfire'

Vue.use(Vuex)

export default new Vuex.Store({
	mutations: {
		...vuexfireMutations
	},
	modules: {
		user,
		resources,
		teachers
	}
})
