import { db } from '@/firebase'
import firebase from 'firebase/app'
import { firestoreAction } from 'vuexfire'

const state = () => ({
    list: [],
})

const getters = {
    loggedInTeacher(state, getters, rootState) {
        return state.list.find(teacher => teacher.id === rootState.user.uid);
    },
    teacherSubCollections(state, getters, rootState) {
        if (!getters.loggedInTeacher) {
            return [];
        }

        let accessGroups = getters.loggedInTeacher.accessGroups;

        if (accessGroups == null) {
            return [];
        }

        return rootState.resources.subCollections.filter(sub => {
            if (sub.accessGroups) {
                return sub.accessGroups.some(group => accessGroups.map(g => g.id).includes(group.id));
            } else {
                return false;
            }
        }).map(sub => sub.id);
    },
    teacherVideos(state, getters, rootState) {
        if (!getters.loggedInTeacher) {
            return [];
        }

        let accessGroups = getters.loggedInTeacher.accessGroups;

        if (accessGroups == null) {
            return [];
        }

        return rootState.resources.subCollections.filter(sub => {
            if (sub.accessGroups) {
                return sub.accessGroups.some(group => accessGroups.map(g => g.id).includes(group.id));
            } else {
                return false;
            }
        }).reduce((prev, curr) => {
            prev.push(...curr.items)
            return prev;
        }, []);
    },
    teacherWatchedVideos(state, getters) {
        if (!getters.loggedInTeacher) {
            return [];
        }

        if (!getters.loggedInTeacher.watchedVideos) {
            return [];
        }

        return getters.loggedInTeacher.watchedVideos;
    }
}

const actions = {
    bindList: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('list', db.collection('teachers'))
    }),
    async recordTeacherLoginDate({ rootState }) {
        await db.collection('teacherAccessDates').doc(rootState.user.uid).set({
            lastAccessDate: new Date()
        })
    },
    async recordVideoWatched({ getters, rootState }, video) {
        if (getters.loggedInTeacher) {
            await db.collection('teachers').doc(rootState.user.uid).update({
                watchedVideos: firebase.firestore.FieldValue.arrayUnion(video)
            })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions
}